* {
  box-sizing: border-box;
  font-family: "Karla", sans-serif;
  color: #033d35;
  /* background-color: black; */

}
body{
  box-sizing: border-box;
  
}

.bodymargin{
  margin: 0 3% 0 3%;
}
.orange{
  color: #e76e50;
}
.suman {
  font-family: "Lato", sans-serif;
  font-weight: 800;
}
.subheadline {
  font-family: "PT Sans", sans-serif;
}
.glamor{
  font-family: 'Tenor Sans', sans-serif;
  color: #903643;
}
.glamorwhite{
  font-family: 'Tenor Sans', sans-serif;

}
.sinai{
  font-family: 'Inter', sans-serif;
  color: #8B482D;
}
.sinaiwhite{
  font-family: 'Inter', sans-serif;

}
.job{
  font-family: 'Inter', sans-serif;
  color: #000;
}
.jobwhite{
  font-family: 'Inter', sans-serif;

}
.jwell{
  font-family: 'Cormorant Garamond', serif;
  color: #424B54;
}
.jwellwhite{
  font-family: 'Cormorant Garamond', serif;

}
.raikargoodvibes{
  font-family: 'Baskervville', serif;
  color: #29003C;
}
.raikargoodvibeswhite{
  font-family: 'Baskervville', serif;

}
.rakarbg{
  background-image: url("../src/assets/raikargoodvibes/textbackground.png");
}
.gradient {
  box-shadow: 1px 0 5px -5px #333, 0 -5px 12px -12px #333, -1px 0 5px -5px #333;
}
.shadowsuman{
  box-shadow: 0 -5px 5px -5px #e76e50;
}
/* .shadowsuman {
  
  box-shadow: 0 -5px 5px -5px #e76e50;
  background: linear-gradient(
    to top,
    #ffffff 20%, 
    #ffcdc0 40% ,
    #ffcdc0 40% ,
    #e76e50 2% 
    );
} */
/* .sumanright {
  box-shadow: -5px 0 5px -5px #333;
} */
/* .bottom  */
/* box-shadow: 0 5px 5px -5px #333; */

/* .left  */
/* box-shadow: 5px 0 5px -5px #333; */

.bottom {
  box-shadow: 0px 15px 10px -15px #ffe1da;
}
.hero-image::after {
  display: block;
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0,
    #fff 100%
  );
  bottom: 0;
  left: 0;
  right: 0;
  height: 65vh;
  width: 100%;
  content: "";
}

/* CSS */
.button-dark {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 18px;
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 7px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-dark::after,
.button-dark::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.button-dark::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #033d35;
  border-radius: 10px;
}

.button-dark::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-dark:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-dark:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-dark:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

/* Light */

/* CSS */
.buttonlight {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 18px;
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 7px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.buttonlight::after,
.buttonlight::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.buttonlight::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #e76e50;
  border-radius: 10px;
}

.buttonlight::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.buttonlight:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.buttonlight:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.buttonlight:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

/* CSS */
.buttonsmall {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 14px;
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.buttonsmall::after,
.buttonsmall::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.buttonsmall::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #e76e50;
  border-radius: 10px;
}

.buttonsmall::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.buttonsmall:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.buttonsmall:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.buttonsmall:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

.buttonsmalldark {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 14px;
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.buttonsmalldark::after,
.buttonsmalldark::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.buttonsmalldark::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #033d35;
  border-radius: 10px;
}

.buttonsmalldark::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.buttonsmalldark:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.buttonsmalldark:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.buttonsmalldark:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}


.screen {
	display: block;
	width: 100%;
	height: 350px;
	overflow: hidden;
	position: relative;

}
.screen img {
	bottom: -1210px;
	width: 100%;
	height: auto;
	position: absolute;
	z-index: 0;
  margin:0;
  padding:0;
	-webkit-transition: top 11s;
	-moz-transition: top 11s;
	-ms-transition: top 11s;
	-o-transition: top 11s;
	transition: bottom 11s;
}
.screen:hover img {
  bottom: 0;
  -webkit-transition: all 11s;
  -moz-transition: all 11s;
  -ms-transition: all 11s;
  -o-transition: all 11s;
  transition: all 11s;
}