
  

  .grids > div {
    animation: moveUpAndDown 20s linear infinite;
    overflow: hidden;
    border-radius: 0.5rem; /* To maintain rounded corners */
    position: relative;
  }

  .grids > div:nth-child(1) {
    animation: moveUpAndDown 75s linear infinite, moveUp 50s infinite;
  }

  .grids > div:nth-child(2) {
    animation: moveUpAndDown 45s linear infinite, moveDown 75s infinite;
  }
  .grids > div:nth-child(3) {
    animation: moveUpAndDown 65s linear infinite, moveUp 40s infinite;
  }

  .grids > div:nth-child(4) {
    animation: moveUpAndDown 50s linear infinite, moveDown 40s infinite;
  }

  @keyframes moveUpAndDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-30%);
    }
  }

  @keyframes moveUp {
    0%, 100% {
      transform: translateY(-25%);
    }
    50% {
      transform: translateY(25%);
    }
  }

  @keyframes moveDown {
    0%, 100% {
      transform: translateY(25%);
    }
    50% {
      transform: translateY(-25%);
    }
  }

